import { FluentResource } from '@fluent/bundle';

export default new FluentResource(`
## General

-brand-name = Reducept
-ecurring = eCurring
-stripe = Stripe
-mailchimp = MailChimp
-appsflyer = AppsFlyer
-google_analytics = Google Analytics
-support_link = info@reducept.com
support_link = { -support_link }
website_support_link = { $locale ->
  [nl] https://www.reducept.com/nl/support
  [de] https://www.reducept.com/de/support
  *[other] https://www.reducept.com/support
}

## Selectors/Terms

-user = { $amount ->
  *[one] { $casing ->
    *[upper] Gebruiker
    [lower] gebruiker
  }
  [other] { $casing ->
    *[upper] Gebruikers
    [lower] gebruikers
  }
}

-practice = { $amount ->
  *[one] { $casing ->
    *[upper] Praktijk
    [lower] praktijk
  }
  [other] { $casing ->
    *[upper] Praktijken
    [lower] praktijken
  }
}

-partner = { $amount ->
  *[one] { $casing ->
    *[upper] Partner
    [lower] partner
  }
  [other] { $casing ->
    *[upper] Partners
    [lower] partners
  }
}

-player-account = { $amount ->
  *[one] { $casing ->
    *[upper] Speler account
    [lower] speler account
  }
  [other] { $casing ->
    *[upper] Speler accounts
    [lower] speler accounts
  }
}

-device = { $amount ->
  *[one] { $casing ->
    *[upper] Apparaat
    [lower] apparaat
  }
  [other] { $casing ->
    *[upper] Apparaten
    [lower] apparaten
  }
}

-subscription = { $amount ->
  *[one] { $casing ->
    *[upper] Abonnement
    [lower] abonnement
  }
  [other] { $casing ->
    *[upper] Abonnementen
    [lower] abonnementen
  }
}

-subscription_type = { $amount ->
  *[one] { $casing ->
    *[upper] Abonnementstype
    [lower] abonnementstype
  }
  [other] { $casing ->
    *[upper] Abonnementstypes
    [lower] abonnementstypes
  }
}

-role = { $amount ->
  *[one] { $casing ->
    *[upper] Rol
    [lower] rol
  }
  [other] { $casing ->
    *[upper] Rollen
    [lower] rollen
  }
}

-administrator = { $amount ->
  *[one] { $casing ->
    *[upper] Beheerder
    [lower] beheerder
  }
  [other] { $casing ->
    *[upper] Beheerders
    [lower] beheerders
  }
}

-patient = { $amount ->
  *[one] { $casing ->
    *[upper] Patiënt
    [lower] patiënt
  }
  [other] { $casing ->
    *[upper] Patiënten
    [lower] patiënten
  }
}

-therapist = { $amount ->
  *[one] { $casing ->
    *[upper] Therapeut
    [lower] therapeut
  }
  [other] { $casing ->
    *[upper] Therapeuten
    [lower] therapeuten
  }
}

-owner = { $amount ->
  *[one] { $casing ->
    *[upper] Eigenaar
    [lower] eigenaar
  }
  [other] { $casing ->
    *[upper] Eigenaren
    [lower] eigenaren
  }
}

-group = { $amount ->
  *[one] { $casing ->
    *[upper] Groep
    [lower] groep
  }
  [other] { $casing ->
    *[upper] Groepen
    [lower] groepen
  }
}

-license = { $amount ->
  *[one] { $casing ->
    *[upper] Licentie
    [lower] licentie
  }
  [other] { $casing ->
    *[upper] Licenties
    [lower] licenties
  }
}

-access_period = { $amount ->
  *[one] { $casing ->
    *[upper] Toegangs periode
    [lower] toegangs periode
  }
  [other] { $casing ->
    *[upper] Toegang periodes
    [lower] toegang periodes
  }
}

-professional_invite = { $amount ->
  *[one] { $casing ->
    *[upper] Uitnodiging
    [lower] uitnodiging
  }
  [other] { $casing ->
    *[upper] Uitnodigingen
    [lower] uitnodigingen
  }
}

-password = { $casing ->
  *[upper] Wachtwoord
  [lower] wachtwoord
}

-email = { $casing ->
  *[upper] E-mail
  [lower] e-mail
}

-ecurring_customer = { $amount ->
  *[one] { -ecurring } klant
  [other] { -ecurring } klanten
}

-stripe_account = { $amount ->
  *[one] { -stripe } account
  [other] { -stripe } accounts
}

-mailchimp_account = { $amount ->
  *[one] { -mailchimp } account
  [other] { -mailchimp } accounts
}

-mailchimp_tag = { $amount ->
  *[one] { -mailchimp } tag
  [other] { -mailchimp } tags
}

-log = { $amount ->
  *[one] { $casing ->
    *[upper] Log
    [lower] log
  }
  [other] { $casing ->
    *[upper] Logs
    [lower] logs
  }
}

-pain_score = { $amount ->
  *[one] { $casing ->
    *[upper] Pijn score
    [lower] pijn score
  }
  [other] { $casing ->
    *[upper] Pijn scores
    [lower] pijn scores
  }
}

-pain_location = { $amount ->
  *[one] { $casing ->
    *[upper] Pijn locatie
    [lower] pijn locatie
  }
  [other] { $casing ->
    *[upper] Pijn locaties
    [lower] pijn locaties
  }
}

-game_chapter = { $amount ->
  *[one] { $casing ->
    *[upper] Hoofdstuk
    [lower] hoofdstuk
  }
  [other] { $casing ->
    *[upper] Hoofdstukken
    [lower] hoofdstukken
  }
}

-comment = { $amount ->
  *[one] { $casing ->
    *[upper] Opmerking
    [lower] opmerking
  }
  [other] { $casing ->
    *[upper] Opmerkingen
    [lower] opmerkingen
  }
}

## Models

user = { -user }
users = { -user(amount: 2) }

practice = { -practice }
practices = { -practice(amount: 2) }
professional = { -practice }
professionals = { -practice(amount: 2) }

partner = { -partner }
partners = { -partner(amount: 2) }

organization = { partner }
organizations = { partners }

player_account = { -player-account }
player_accounts = { -player-account(amount: 2) }
player_account_for = { -player-account } van

device = { -device }
devices = { -device(amount: 2) }

subscription = { -subscription }
subscription_of_model = { -subscription } van { $model }
subscriptions = { -subscription(amount: 2) }

subscription_type = { -subscription_type }
subscription_types = { -subscription_type(amount: 2) }
provider_type = { -subscription_type }
provider_types = { -subscription_type(amount: 2) }

role = { -role }
roles = { -role(amount: 2) }

administrator = { -administrator }
administrators = { -administrator(amount: 2) }
admin = { -administrator }

patient = { -patient }
patients = { -patient(amount: 2) }

therapist = { -therapist }
therapists = { -therapist(amount: 2) }

owner = { -owner }
owners = { -owners(amount: 2) }

group = { -group }
groups = { -group(amount: 2) }

access_period = { -access_period }
access_periods = { -access_period(amount: 2) }

professional_invite = { -professional_invite }
professional_invites = { -professional_invite(amount: 2) }

staff = Personeel

ecurring_customer = { -ecurring_customer }
ecurring_customers = { -ecurring_customer(amount: 2) }

stripe_account = { -stripe_account }
stripe_accounts = { -stripe_account(amount: 2) }

mailchimp_account = { -mailchimp_account }
mailchimp_accounts = { -mailchimp_account(amount: 2) }

mailchimp_tag = { -mailchimp_tag }
mailchimp_tags = { -mailchimp_tag(amount: 2) }

log = { -log }
logs = { -log(amount: 2) }

pain_score = { -pain_score }
pain_scores = { -pain_score(amount: 2) }

pain_location = { -pain_location }
pain_locations = { -pain_location(amount: 2) }

game_chapter = { -game_chapter }
game_chapters = { -game_chapter(amount: 2) }

comment = { -comment }
comments = { -comment(amount: 2) }

## Menu items

dashboard = Dashboard

## Buttons

add = Toevoegen
new = Nieuw
edit = Aanpassen
save = Opslaan
delete = Verwijder
cancel = Annuleer
submit = Opslaan
change-password = Verander { -password(casing: "lower") }
invite-patient-button = { -patient } uitnodigen
invite-staff-button = Personeel uitnodigen
import = Importeer
export = Exporteer
search = Zoeken
search-model = Zoek { $model }
extend-access = Toegang verlengen
extend-by-3-months = Verleng met 3 maanden
login = Log in
logout = Log uit
accept = Accepteer
resend = Verstuur opnieuw

## Model specific

add-model = Voeg { $model } toe
update-model = Pas { $model } aan
delete-model = Verwijder { $model }
not-found = Geen { $model } gevonden
general-data = Algemene { $model } informatie

------

## Fields used throughout the Dashboard

roleId = Rol
created_at = Aangemaakt op
added_at = Toegevoegd op
updated_at = Laatst gewijzigd op
synced_at = Gesynchroniseerd op
synced_at_description = De laatste keer dat er een synchronisatie gedaan is met de externe betalingsprovider. Dit is alleen relevant voor { -stripe } en { -ecurring } { -subscription(amount: 2, casing: "lower") }
since = Sinds
created_by = Aangemaakt door
id = Id
password = { -password }
password_confirmation = Bevestig { -password(casing: "lower") }
password_changed_success = { -password } is veranderd
email = { -email }
first_name = Voornaam
middle_name = Tussenvoegsel
last_name = Achternaam
language = Taal
name = Naam
has_access = Heeft toegang
has_access_description = De { -patient(casing: "lower") } heeft toegang tot { -brand-name }
active = Actief
active_subscription_id = { active }
active_subscription_id_description = De { -practice(casing: "lower") } heeft een actief { -subscription(casing: "lower") }
subscription_professional_owner_expired =  Het { -subscription(casing: "lower") } bij { $professional_name } is verlopen
subscription_professional_therapist_expired =  Het { -subscription(casing: "lower") } bij { $professional_name } is verlopen. Contacteer de { -practice(casing: "lower") }{ -owner(casing: "lower") }
access_until = Toegang tot
access_until_description = De { -patient(casing: "lower") } heeft toegang tot deze datum
groups_description = De { -group(amount: 2, casing: "lower") } waartoe de { -patient(casing: "lower") } behoort
user_groups = De { -group(amount: 2, casing: "lower") } waartoe de { -user(casing: "lower") } behoort
practice_table_tooltip = Deze praktijk heeft een onbeperkt aantal licenties
practice_id = Praktijk
practice_owner = { -practice(casing: "upper") } eigenaar
practice_owner_description = De gebruiker die eigenaar dient te zijn van deze { -practice(casing: "lower") } Er kan ook een niet bestaande gebruiker aangemaakt worden door op 'Nieuw' te klikken
professionals_count = Aantal { -practice(amount: 2, casing: "lower") }
practice_id_description = De { -practice(casing: "lower") } die wordt beinvloed door dit { -subscription(casing: "lower") }
professional_group = { group } (optioneel)
professional_group_description = De { -group(casing: "lower") }(en) waartoe deze { -patient(casing: "lower") } behoort. Dit is optioneel
professional_groups = { -group(amount: 2, casing:"upper") } (optioneel)
professional_groups_description = De { -group(amount: 2, casing: "lower") } waartoe de { -patient(casing: "lower") } behoort. Dit is optioneel
professional_group_users = { -user(amount: 2) }
professional_group_users_count = Aantal { -user(amount: 2, casing: "lower") }
practices_table_tooltip = Deze { -practice(amount:2, casing: "lower") } heeft een onbeperkt aantal licenties
associated_practice = Gekoppelde { -practice(casing: "lower") }
associated_practices = Gekoppelde { -practice(amount: 2, casing: "lower") }
associated_practices_description = De { -practice(amount: 2, casing: "lower") } waar deze { $model } bij hoort
associated_user = Gekoppelde { -user(casing: "lower") }
associated_group_users = De gekoppelde { -user(amount: 2, casing: "lower") }
unclaimed = Beheerd door { -therapist(casing: "lower") }
unclaimed_description = De { -user(casing: "lower") } is een { -patient(casing: "lower") } zonder een eigen { -brand-name } account. De { -practice(casing: "lower") } beheert het account
email_verified_at = Geverifieerd op
email_verified_at_description = De { -user(casing: "lower") } heeft z'n { -email(casing: "lower") } geverifieerd
last_activity_at = Laatste activiteit op
has_password = Heeft wachtwoord
practice_description = De gewenste { -practice(casing: "lower") } om een { -subscription(casing: "lower") } aan toe te voegen
professional_description = { practice_description }
user_description = De gewenste { -user(casing: "lower") } om een { -subscription(casing: "lower") } aan toe te voegen
patient_description = De { -user(casing: "lower") } is een { -patient(casing: "lower") }
home_usage = Thuisgebruik ingeschakeld
home_usage_description = Door dit in te schakelen mag de { -patient(casing: "lower") } { -device(casing: "lower", amount: 2) } van de { -practice(casing: "lower") } gebruiken om zelf in te loggen. Kan alleen gebruikt worden door { -user(casing: "lower", amount: 2) } met een { -email(casing: "lower") }
home_usage_practice_description = De { -user(casing: "lower") } mag { -device(amount: 2, casing: "lower") } van de { -practice(casing: "lower") } gebruiken om zelf in te loggen
patient_license_note = Note: wanneer er een { -patient(casing: "lower") } aangemaakt wordt zal er automatisch één licentie gebruikt worden om deze { -patient(casing: "lower") } toegang te geven tot Reducept. Momenteel zijn er { $model } licenties beschikbaar binnen de huidige periode
patient_license_note_unlimited = Note: wanneer er een { -patient(casing: "lower") } aangemaakt wordt zal er automatisch één licentie gebruikt worden om deze { -patient(casing: "lower") } toegang te geven tot Reducept.
redeem_access_period = Geef { -patient(casing: "lower") } direct toegang
redeem_access_period_description = De { -patient(casing: "lower") } krijgt gelijk toegang tot { -brand-name} zodra het account aangemaakt is. Hiervoor wordt er één { -license(casing: "lower") } gebruikt
start_date = Startdatum
start_date_description = De datum waarop het { -subscription(casing: "lower") } start. De datum kan niet aangepast worden als het { -subscription(casing: "lower") } al gestart is
end_date = Einddatum
end_date_description = De datum waarop het { -subscription(casing: "lower") } eindigt. Kan leeg gelaten worden.
date = Datum
minimum_length = Minimale lengte: { $length }
period_start = Start van de periode
period_start_description = De datum waarop de { -access_period(casing: "lower") } start
period_end = Einde van de periode
period_end_description = De datum waarop de { -access_period(casing: "lower") } eindigt
current_period_start = Start van de huidige periode
current_period_end = Einde van de huidige periode
paused_at = Gepauzeerd per
resumed_at = Wordt hervat per
resume_date = Hervat op
cancelled_at = Geannuleerd op
cancel_date = { cancelled_at }
cancel_at = Wordt geannuleerd per
ended_at = Beëindigd op
staff_therapist_role = Personeelsleden kunnen momenteel alleen { -therapist(amount: 2, casing: "lower") } zijn
staff_description = De { -user(casing: "lower") } is een personeelslid binnen de { -practice(casing: "lower") }
access_periods_used_by = De { -access_period(amount: 2, casing: "lower") } die gebruikt zijn door de { $model }
devices_description = De { -device(amount: 2, casing: "lower") } die gebruikt worden door de { -user(casing: "lower") }
information = Informatie
device_type = Type
device_model = Model
device_login_information = { -device } log in informatie
login_patient_on_device = De { -patient(casing: "lower") } die op dit { -device(casing:"lower") } ingelogd wordt. Game data wordt opgeslagen binnen het account van de { -patient(casing: "lower") }
login-patient = Log { -patient(casing: "lower") } in op dit { -device(casing:"lower") }
operating_system = Besturingssysteem
operating_system_family = Besturingssysteem familie
ecurring_customer_id = { -ecurring_customer } id
ecurring_customer_description = Het gekoppelde { -ecurring } account
ecurring_plan_id = { -ecurring } plan id
ecurring_id = { -ecurring } id
archived = Gearchiveerd in { -ecurring }
type = Type
context = Context
message = Bericht
logs_for_model = Aangemaakte { -log(amount: 2, casing: "lower") } voor de { $model }
mailchimp_account_description = Het gekoppelde { -mailchimp_account }
link_to_mailchimp_website = Link naar { -mailchimp } website
mailchimp_id = { -mailchimp } id
status = Status
status_description = De status van het { -subscription(casing: "lower") } binnen de betalingsprovider
last_changed = { updated_at }
timestamp_opt = { created_at }
unsubscribe_reason = Reden voor uitschrijving
mailchimp_tags_description = De gekoppelde { -mailchimp_tag(amount: 2) }
title = Titel
meta_data = Meta data
meta_data_description = Administratieve data
ga_cid = { -google_analytics } id
exclude_reports = Uitgesloten van rapportages
appsflyer_cuid = { -appsflyer } id
registration_origin = Herkomst registratie
personal = Persoonlijk
average = Gemiddelde
measured_pain_scores = Statistieken over { $username }'s gemeten { -pain_score(amount: 2, casing: "lower") }
measured_pain_locations = { $username }'s gemeten { -pain_location(amount: 2, casing: "lower") }
player_accounts_description = De { -player-account(amount: 2, casing: "lower") } van de { -user(casing: "lower") }
brain_level = Brein niveau
nerve_level = Zenuw niveau
nerve_highscore = Zenuw highscore
chapters_played = Gespeelde { -game_chapter(amount: 2, casing: "lower") }
last_played = Laatst gespeeld
access = Heeft toegang
no_access_row_description = De { -user(casing: "lower") } heeft geen toegang tot { -brand-name } omdat de { -practice(casing: "lower") } geen actief abonnement heeft
access_row_description = De { -user(casing: "lower") } heeft toegang tot { -brand-name } via de { -practice(casing: "lower") } omdat de { -practice(casing: "lower") } een actief abonnement heeft
access_description = De { -user(casing: "lower") } heeft (geen) toegang tot { -brand-name } via de { -practice(casing: "lower") }
contact = Contactpersoon
contact_description = De { -user(casing: "lower") } is een contactpersoon voor de { -practice(casing: "lower") }
roles_description = De { -role(amount: 2, casing: "lower") } van de { -user(casing: "lower") }
stripe_account_description = Het gekoppelde { -stripe_account(casing: "lower") }
stripe_id = { -stripe } id
subscriptions_for_model = De gekoppelde { -subscription(amount: 2, casing: "lower") } voor de { -user(casing: "lower") }
subscription_holder = Abonnementshouder
practice_subscription_holder = De { -user(casing: "lower") } is de { -subscription(casing: "lower") }shouder binnen de { -practice(casing: "lower") }
error_message = Er ging iets mis. Neem contact op met support via { -support_link }
practices_count = Aantal { -practice(amount: 2, casing: "lower") }
active_subscription = Actief { -subscription(casing: "lower") }
active_subscriptions = Aantal actieve { -subscription(amount: 2, casing: "lower") }
active_subscriptions_description = Het aantal { -practice(amount: 2, casing: "lower") } binnen de { -partner(casing: "lower") } met een actief { -subscription(casing: "lower") }
practice_id = { practice }
practice_id_description = De { -practice(casing: "lower") } waaraan het { -subscription(casing: "lower") } hangt
user_id = { user }
user_id_description = De { -user(casing: "lower") } aan wie het { -subscription(casing: "lower") } hangt
has_finished_introduction = Introductie voltooid
last_brain_pattern_length = Laatste breinpatroon lengte
users_count = Aantal { -user(amount: 2, casing: "lower") }
licensing = { -license }
licensing_description = { -license } informatie
licensing_only_practices = Alleen { -practice(casing: "lower") } { -subscription(amount: 2, casing: "lower") } gebruiken { -license(amount: 2, casing: "lower") }
licenses_per_period = Aantal { -license(amount: 2, casing: "lower") } per periode
licenses_redeemed_in_current_period = Aantal gebruikte { -license(amount: 2, casing: "lower") } in periode
licenses_available_in_current_period = Aantal { -license(amount: 2, casing: "lower") } beschikbaar
licenses_available_in_current_period_description = Het aantal beschikbare { -license(amount: 2, casing: "lower") } voor de { -practice(casing: "lower") } gedurende de huidige periode
current_licensing_period = Huidige periode
licenses_reset_interval = { -license } reset periode
licenses_reset_interval_description = De regelmaat waarop de { -license(amount: 2, casing: "lower") } gereset worden
partner_id = { partner }
partner_description = De { -partner(casing: "lower") } waartoe de { -practice(casing: "lower") } behoort
overview = Overzicht
unlimited = Oneindig
year = Jaarlijks
month = Maandelijks
amount_licenses = Aantal { -license(amount: 2, casing: "lower") }
amount_licenses_description = Het aantal beschikbare { -license(amount: 2, casing: "lower")} per { licenses_reset_interval }. Benodigd voor maandelijkse of jaarlijkse reset periodes
logged_in_player = Ingelogde { -user(casing: "lower") }
login_logout = Log in / uit
has_active_subscription = Heeft een actief { -subscription(casing: "lower") }
game_version = Reducept versie
game_api_version = Game API
given_device_name = Gegeven naam
device_name = Originele naam
device_unique_identifier = Uniek identificatie nummer
specification = Specificatie
device_specification = { -device } specificatie
supports_audio = Ondersteunt geluid
manual = Handmatig
trial = Proefperiode
stripe = { -stripe }
ecurring = { -ecurring }
manual_only = alleen handmatig
practice_subscription_description = De { -practice(casing: "lower") } die het { -subscription(casing: "lower") } gebruikt
provider_type_user_description = Het gewenste { -subscription_type(casing: "lower") } voor deze { -user(casing: "lower") }. Op het moment zijn alleen handmatige en proef { -subscription(amount: 2, casing: "lower") } beschikbaar
provider_type_practice_description = Het gewenste { -subscription_type(casing: "lower") } voor deze { -practice(casing: "lower") }.
user_subscription_description = De { -user(casing: "lower") } die het { -subscription(casing: "lower") } gebruikt
provider_type_information = { -subscription_type } informatie
mandate_accepted_date = Mandaat geaccepteerd op
admin_since = { -administrator } sinds
filter_value = Filter waarde
contains_filter_label = Bevat
no_data_graph = Er is geen data om weer te geven
deleted = verwijderd
close = Sluiten
access_period_user_deleted_tooltip = De { -user(casing: "lower") } is op een moment verwijderd. De uitgegeven { -access_period(casing: "lower") } kan niet opnieuw uitgegeven worden
device_deleted = { -device } { deleted }
comments_for_model =  Aangemaakte { -comment(amount: 2, casing: "lower") } voor { $model }
licensing_only_professionals = Alleen { -practice(casing: "lower") } { -subscription(amount: 2, casing: "lower") } maken gebruik van { -license(amount: 2, casing: "lower") }
responded_at = Geaccepteerd
responded_at_date = Geaccepteerd op
invited_user = Uitgenodigde { -user(casing: "lower") }
invited_user_email = Uitgenodigde { -user(casing: "lower") } { -email(casing: "lower") }
open_professional_invites = Open { -professional_invite(amount: 2, casing: "lower") }
resend_professional_invite = Verstuur { -professional_invite(casing: "lower") } opnieuw
last_sent_at = Laatst verstuurd op
reset-password-dialog-description = Wil je een email met een wachtwoord herstel link naar deze { -user(casing: "lower" )} sturen?
reset-password-email-sent = Wachtwoord herstel email is verstuurd
reset-password-tooltip-user-has-no-password = { -user } heeft nog geen wachtwoord
patient_never_had_access = Deze { -patient(casing: "lower") } heeft nooit toegang gehad
access_expired_on = Toegang is verlopen op { $expiredOn }
demo_account_access = De { -patient(casing: "lower") } heeft toegang omdat het een demo account is

## Log types

create = Creēer
token_refresh = Token verversing
created_from_stripe_webhook = Gecreēerd door { -stripe } webhook
synchronization = Synchronisatie
google_analytics = { -google_analytics }
bad_request = Bad request
password_reset = { -password } herstel
subscription = subscription
mailchimp = { -mailchimp }
access_period = { -access_period }
resend_confirm_account = Account bevestigingsmail opnieuw verzenden
account_confirmed = Account bevestigd
password_changed = { -password } veranderd
detached_from_professional = Losgekoppeld van { -practice(casing: "lower") }
role_added = { -role } toegevoegd
role_removed = { -role } verwijderd
professional_invite_accepted = Uitnodiging geaccepteerd van { -practice(casing: "lower") }
professional_invite_sent = Uitnodiging verstuurd naar { -user(casing: "lower") }

## Stripe subscription status

canceled = Geannuleerd
incomplete = Niet compleet
incomplete_expired = Niet compleet (verlopen)
past_due = Verlopen
trialing = Proefperiode
unpaid = Niet betaald

## Ecurring subscription status

cancelled = Geannuleerd
paused = Gepauzeerd
unverified = Ongeverifieerd
suspended = Geschorst

## MailChimp member status

subscribed = Geabonneerd
unsubscribed = Uitgeschreven
cleaned = Opgeschoond
pending = In afwachting
transactional = Transactional

## Registration origin

b2b2c = B2B2C
website = Website
unknown = Onbekend

## Form helper texts

required = Vereist
invalid-email-format = Onjuist { -email(casing: "lower") }formaat

add-admin-help-text =
  Zoek naar { users } door hun informatie in de zoekbox hier beneden in te vullen
  Daarna wordt de 'Admin' { -role(casing: "lower") } aan de geselecteerde { -user(casing: "lower") }(s) toegewezen
delete-admin-help-text =
  Zoek naar { users } door hun informatie in de zoekbox hier beneden in te vullen
  Daarna wordt de 'Admin' { -role(casing: "lower") } van de geselecteerde { -user(casing: "lower") }(s) verwijderd

delete_group_confirm = Weet je zeker dat je de { -group(casing: "lower") } wilt verwijderen? { -user(amount: 2) } binnen de groep zullen NIET verwijderd worden
detach_staff_confirm = Weet je zeker dat je dit personeelslid wilt verwijderen? De { -user(casing: "lower") } zelf zal NIET verwijderd worden. Let op dat een { -practice(casing: "lower") } altijd op z'n minst 1 eigenaar moet hebben!
delete_patient_confirm = Weet je zeker dat je de { -patient(casing: "lower") } wilt verwijderen? De { -user(casing: "lower") } zelf zal niet verwijderd worden als de gebruiker een eigen { -email(casing: "lower") } en { -password(casing: "lower") } heeft en z'n account geverifieerd heeft
delete_group_success = { -group } is verwijderd
detach_staff_success = Personeelslid is losgekoppeld van de { -practice(casing: "lower") }
delete_patient_success = { -patient } is verwijderd
device_user_logged_in = { -user } is ingelogd
device_user_logged_out = { -user } is uitgelogd
delete_device_confirm = Weet je zeker dat je dit { -device(casing: "lower") } wilt verwijderen? Ingelogde { -user(amount: 2, casing: "lower") } zullen automatisch uitgelogd worden.
delete_device_success = { -device } is verwijderd
no_email_set = Geen { -email(casing: "lower") } ingesteld
extend_access_confirm = Je staat op het punt om de { -user(casing: "lower") } 3 maanden toegang te geven
extend_access_success = { -access_period } is verlengd
licenses_left_amount = Je hebt op het moment nog { $amount } { -license(amount: 2, casing: "lower") } over gedurende de huidige periode
datepicker_from_to = Datum van / tot
no_licenses = Geen beschikbare { -license(amount: 2, casing: "lower") } deze periode
no_licenses_starts_at = Geen beschikbare { -license(amount: 2, casing: "lower") } deze periode. Je volgende periode begint op { $start }
no_subscription_found = Geen { -subscription(casing: "lower") } gevonden. Je hebt een abonnement nodig voordat je een { -patient(casing: "lower") } toegang kan geven

# Pages

## Login page

dashboard_welcome = Hallo { $username }
dashboard_body_newsletter = nieuwsbrief
dashboard_body_newsletter_url = https://www.reducept.com/nl/blog/reducept-dashboard-update-een-nieuw-uiterlijk
dashboard_body = We hebben het afgelopen jaar uw feedback gehoord en hebben hard gewerkt om onze technische infrastructuur nog beter te maken. U kunt de releasenotes lezen in onze
sign-in = Log in
login = Log in
login-with-your-account = { login } met je { -brand-name } account
forgot-password = { -password } vergeten?
incorrect_credentials = Onjuiste email en wachtwoord combinatie, probeer het opnieuw. Wachtwoord vergeten? Klik op de '{ forgot-password }' knop hier beneden.
login_incorrect_role_error = Het dashboard kan alleen gebruikt worden door { -brand-name } { -administrator(amount: 2, casing: "lower") }, { -partner(amount: 2, casing: "lower") }, en { -therapist(amount: 2, casing: "lower") }. Als U een { -patient(casing: "lower") } bent bij een { -practice(casing: "lower") }, neem dan contact op met je { -practice(casing: "lower") }{ -owner(casing: "lower") }. Stuur een email naar { -support_link } als U geen { -patient(casing: "lower") } bent en U er zeker van bent dat U toegang zou moeten hebben tot het dashboard

## Reset password page

reset-password = Herstel { -password(casing: "lower") }
reset-password-message = Vul je nieuwe { -password(casing: "lower") } in
send-reset-password-link = Verstuur { -password(casing: "lower") } herstel link
back-to-sign-in = Terug naar log in
reset-password-message = Vul je { -email(casing: "lower") } in en wij sturen je een { -password(casing: "lower") } herstel link
reset-password-sent-message = We hebben je een { -email(casing: "lower") } verstuurd met instructies over hoe je jouw { -password(casing: "lower") } kan herstellen
password_not_match = De wachtwoorden komen niet overeen

## Register page

set-password = Vul je { -password(casing: "lower") } in
set-password-message = Vul je nieuwe { -password(casing: "lower") } in
invalid_token_part_1 = Onjuiste bevestigings token
invalid_token_part_2 = Als je hier gekomen bent via de link in je email, neem dan contact op met support.
invalid_token_part_3 = Zie
register_submit_general_error = Er ging iets mis. Waarschijnlijk is je account al geregistreerd en heb je al een wachtwoord gekozen. Als dit niet het geval is, neem dan contact op met support via { -support_link }

## Account confirmed page

account_confirmed_part_1 = Alles is nu gereed om te gebruik te gaan maken van { -brand-name }!
account_confirmed_part_2 = {""}
account_confirmed_part_3 = Ben je een { -practice(casing: "lower") }{ -owner(casing: "lower") } of { -therapist(casing: "lower") }? Log dan in via het dashboard om verder te gaan. Ben je een { -patient(casing: "lower") }? Dan kun je nu van de { -patient(casing: "lower") } app gebruik maken op je VR headset of mobiele telefoon. Gebruik hiervoor de ontvangen inloggegevens.
account_confirmed_part_4 = De Reducept app is verkrijgbaar in de App Store (Pico VR bril), de App Store (Apple) en de Google Play Store (Android).
account_confirmed_part_5 = Aarzel bij problemen niet om contact met ons op te nemen! Dit kan door een bericht te sturen naar { -support_link }.
account_confirmed_back_to_sign_in = Terug naar inloggen


## Professional invite pages

professional-invite__page-title = Nodig een { -user(casing: "lower") } uit
professional-invite__email-description = Vul het { -email(casing: "lower") } van een { -user(casing: "lower") } in met een bestaand { -brand-name } account. De { -user(casing: "lower") } zal daarna een { -email(casing: "lower") } ontvangen waarmee ze toe kunnen treden tot je { -practice(casing: "lower") }
professional-invite__role-description = Selecteer de { -role(casing: "lower") } die aan de { -user(casing: "lower") } toegewezen zal worden. Alleen de { -practice(casing: "lower") }{ -owner(casing: "lower") } kan { -user(casing: "lower", amount: 2) } uitnodigen om { -practice(casing: "lower") }{ -owner(casing: "lower") } te worden.
professional-invite__accepted-part-1 = Uitnodiging is geaccepteerd. Je bent vanaf dit moment aangesloten bij de { -practice(casing: "lower") }!
professional-invite__accepted-part-2 = Deze pagina kan veilig gesloten worden
professional-invite__question = Je bent uitgenodigd door <bold_text>{ $professional_name }</bold_text> om een <bold_text>{ $role }</bold_text> te worden binnen de { -practice(casing: "lower") }. Accepteer je deze uitnodiging?
professional-invite__resend-not-allowed-tooltip = Je mag een { -professional_invite(casing: "lower") } eens per 5 minuten versturen

## Navbar

administration = Administratie
navbar_license_info = { -license } informatie
navbar_license_available = Beschikbaar: { $current_amount } / { $maximum_amount }
navbar_period_refreshes_at = Einde van periode: { $date }
navbar_professional_information_error_message = Er ging iets mis bij het laden van informatie over uw { -practice(casing: "lower") }.  Neem contact op met support via { -support_link }

`);
